/* General styles for the container and form */
.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  height: 100vh; /* Full viewport height */
  padding: 20px;
  background-color: #f4f4f9;
}

.input-container form {
  width: 450px;
  max-height: 80vh;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Enables scrolling inside the form if content overflows */
}

/* Styles for form inputs and labels */
.Bank-lable {
  width: 100%;
  margin-bottom: 20px;
}

.Bank-lable input {
  width: 100%;
  height: 50px; /* Increased height for better focus and readability */
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 16px; /* Larger font size for readability */
  transition: all 0.3s ease; /* Smooth transition for interactive elements */
}

.Bank-lable input:focus {
  outline: none;
  border-color: #03a9f4;
  box-shadow: 0 0 8px rgba(3, 169, 244, 0.3); /* Subtle glow effect on focus */
}

/* Styles for buttons */
.input-container button {
  width: auto; /* Adjust width to fit content */
  padding: 12px 20px; /* Padding for better touch interaction */
  font-size: 16px;
  letter-spacing: 1px; /* Spacing for better readability */
  transition: background-color 0.3s, transform 0.2s; /* Animation for hover effects */
}

.input-container button:hover {
  background-color: #0290d8; /* Slightly darker shade on hover */
  transform: translateY(-2px); /* Subtle lift effect */
}

/* Header styles */
.Bank-headings h2 {
  margin: 0;
  font-size: 22px; /* Slightly larger for emphasis */
}

.Bank-headings i {
  font-size: 28px; /* Bigger icon for visual impact */
  background-color: #03a9f4; /* Icon background */
  padding: 5px; /* Spacing around the icon */
  border-radius: 50%; /* Circular background for icon */
  color: white;
  display: flex; /* Center the icon horizontally and vertically */
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 500px) {
  .input-container form {
    width: 90%; /* Full width on smaller screens */
    padding: 20px;
  }
}
