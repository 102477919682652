/*
Template Name: Syndash Admin
Author: codervent
Email: codervent@gmail.com
File: app.css
*/

/*  
  - Google Font
  - General
  - Header
  - Page Wrapper
  - Pricing Table
  - Metismenu
  - Forms
  - Components
  - Buttons
  - Background Colors
  - Text Colors
  - Authentication
  - File Manager
  - Tables
  - Invoice Page
  - Chat box
  - Email box
  - Compose Mail
  - Extra Css
  - Responsive
*/

/* General */

html {
    width: 100%;
    height: 100%;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-family: 'Roboto', sans-serif;
    background-color: #f6f6f6;
    letter-spacing: .1px;
    font-size: 14px;
    overflow-x: hidden
}

a {
    text-decoration: none;
    color: #673ab7;
}

a:hover {
    text-decoration: none;
}

/*Header*/

.wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.top-header {
    width: auto;
    height: 70px;
    line-height: 70px;
    background: #fff;
    position: fixed;
    top: 0;
    left: 260px;
    right: 0;
    z-index: 15;
    border-bottom: 1px solid #e4e4e4;
    box-shadow: none;
}

.sticky-top-header {
    background: #fff;
    border-bottom: 0px solid #e4e4e4;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .075);
}

.toggle-btn {
    margin-right: 10px;
    font-size: 28px;
    color: #404142;
    width: 45px;
    height: 45px;
    line-height: 45px;
    background: transparent;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
}

.toggle-btn:hover {
    color: #404142;
}

.toggle-btn:active {
    color: #404142;
    background: #f1f1f1;
}

.logo-icon {
    width: 145px;
}

.logo-icon-2 {
    width: 35px;
    margin-left: 10px;
}

.logo-text {
    color: #404142;
    font-size: 25px;
    margin-bottom: 0;
    margin-left: 10px;
}

.top-header .navbar {
    padding: 0 1.5rem;
    background: transparent;
}

.left-topbar {
    width: auto;
    height: 70px;
    float: left;
    background: transparent;
}

.right-topbar {
    width: auto;
    height: 70px;
}

.sidebar-header {
    width: 260px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    height: 70px;
    background: #fff;
    border-right: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    z-index: 1;
    transition: all .2s ease-out;
}

.right-topbar .navbar-nav .nav-link {
    padding: 0rem 1.4rem;
    border-left: 1px solid #e4e4e4;
    color: #404142;
    height: 70px;
    font-size: 25px;
}

.search-bar {
    padding-right: 1.5rem
}

.search-arrow-back {
    display: none;
}

.search-btn-mobile {
    display: none;
}

.search-bar .btn-search-back {
    background: #ffffff;
    border: 1px solid #eaeaea;
    padding: 0 14px;
    border-radius: 0px;
    height: calc(1.6em + 0.75rem + 2px);
}

.search-bar .btn-search-back i {
    font-size: 20px;
    color: #727171;
}

.search-bar .btn-search {
    background: #ffffff;
    border: 1px solid #eaeaea;
    padding: 0 25px;
    border-radius: 0px;
    height: calc(1.6em + .75rem + 2px);
}

.search-bar .btn-search i {
    font-size: 16px;
    color: #727171;
}

.search-bar input {
    height: calc(1.6em + .75rem + 2px);
    border: 0;
    border: 1px solid #eaeaea;
    width: 100%;
    border-radius: 2px;
}

.dropdown-toggle-nocaret:after {
    display: none
}

.user-box {
    line-height: 1.3;
    height: 70px;
}

.user-info {
    text-align: right;
    margin-right: 15px;
}

.user-info .user-name {
    font-size: 16px;
    font-weight: 500;
}

.user-info .designattion {
    font-size: 14px;
    color: #a9a8a8;
}

.user-img {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    border: 1px solid #e5e5e5;
    padding: 3px;
}

.user-img img {
    border-radius: 10%;
}

.msg-count {
    position: absolute;
    left: 36px;
    top: 19px;
    font-size: 11px;
    font-weight: 500;
    width: 17px;
    height: 17px;
    text-align: center;
    border-radius: 50%;
    z-index: 5;
    padding: 0;
    color: #fff;
    background-color: #673ab7;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lang i {
    font-size: 18px !important;
}

.lang span {
    font-size: 18px !important;
    padding-left: 6px;
}

.dropdown-menu {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    line-height: initial;
    border: 0px solid #e9ecef;
    margin: 0;
    font-size: 14px;
    border-radius: 15px;
}

.top-header .navbar .dropdown-menu {
    -webkit-animation: .6s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 animdropdown;
    animation: .6s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 animdropdown;
}

.top-header .navbar .dropdown-lg .dropdown-menu::after {
    content: '';
    width: 13px;
    height: 13px;
    background: #673ab7;
    position: absolute;
    top: -6px;
    right: 28px;
    transform: rotate(45deg);
    border-top: 1px solid #673ab7;
    border-left: 1px solid #673ab7;
}

.top-header .navbar .dropdown-menu::after {
    content: '';
    width: 13px;
    height: 13px;
    background: #ffff;
    position: absolute;
    top: -6px;
    right: 28px;
    transform: rotate(45deg);
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
}

@-webkit-keyframes animdropdown {
    from {
        -webkit-transform: translate3d(0, 6px, 0);
        transform: translate3d(0, 6px, 0);
        opacity: 0
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@keyframes animdropdown {
    from {
        -webkit-transform: translate3d(0, 6px, 0);
        transform: translate3d(0, 6px, 0);
        opacity: 0
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

.dropdown-lg {
    position: relative;
}

.dropdown-lg .dropdown-menu {
    width: 355px;
    padding: 0px;
    margin: 0;
    border: 0;
}

.dropdown-lg .dropdown-menu .dropdown-item {
    padding: .5rem 1rem;
    border-bottom: 1px solid #ededed;
}

.dropdown-lg .user-online {
    position: relative;
}

.dropdown-lg .user-online:after {
    content: '';
    position: absolute;
    bottom: 1px;
    right: 17px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #fff;
    background: #16e15e;
}

.dropdown-lg .msg-avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 15px;
}

.dropdown-lg .msg-name {
    font-size: 14px;
    margin-bottom: 0;
}

.dropdown-lg .msg-info {
    font-size: 13px;
    margin-bottom: 0;
}

.dropdown-lg .msg-time {
    font-size: 12px;
    margin-bottom: 0;
    color: #919191;
}

.dropdown-lg .msg-header {
    padding: .5rem 1rem;
    color: #fff;
    border-bottom: 0px solid #ededed;
    background: #673ab7;
    text-align: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.dropdown-lg .msg-header .msg-header-title {
    font-size: 20px;
    margin-bottom: 0;
}

.dropdown-lg .msg-header .msg-header-subtitle {
    font-size: 14px;
    margin-bottom: 0;
}

.dropdown-lg .msg-footer {
    padding: .72rem 1.2rem;
    color: #673ab7;
    border-top: 1px solid #ededed;
    background: transparent;
    font-size: 14px;
    font-weight: 500;
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
}

.dropdown-lg .notify {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 22px;
    text-align: center;
    border-radius: 50%;
    background-color: #f1f1f1;
    margin-right: 15px;
}

.dropdown-user-profile .dropdown-menu i {
    vertical-align: middle;
    margin-right: 10px;
}

.dropdown-user-profile .dropdown-menu span {
    vertical-align: middle;
}

.dropdown-language .dropdown-menu i {
    vertical-align: middle;
    margin-right: 10px;
    font-size: 14px;
}

.dropdown-language .dropdown-menu span {
    vertical-align: middle;
    font-size: 14px;
}

.header-notifications-list {
    position: relative;
    height: 370px;
}

.header-message-list {
    position: relative;
    height: 370px;
}

.dashboard-social-list {
    position: relative;
    height: 240px;
}

.dashboard-top-countries {
    position: relative;
    height: 322px;
}

/*Page Wrapper*/

.page-wrapper {
    position: relative;
    margin-top: 70px;
}

.sidebar-wrapper {
    width: 260px;
    height: calc(100% - 70px);
    position: fixed;
    top: 70px;
    left: 0;
    bottom: 0;
    z-index: 16;
    background: #fff;
    border-right: 1px solid #e4e4e4;
    box-shadow: none;
    transition: all .2s ease-out;
}

.wrapper.toggled .top-header {
    left: 80px;
}

.page-content-wrapper {
    margin-left: 260px;
    transition: all .3s ease-out;
    margin-top: 70px;
    padding-bottom: 20px;
}

.wrapper.toggled .page-content-wrapper {
    margin-left: 80px;
}

.wrapper.toggled .footer {
    margin-left: 80px;
}

.page-content {
    padding: 1.5rem;
}

/*Pricing Table */

.lis-bg-light {
    background-color: #00000005
}

.lis-light {
    color: #707070
}

.price-table {
    -webkit-transition: 0.3s ease
}

.lis-brd-light {
    border-color: #dadada !important
}

.lis-rounded-top {
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
}

.lis-bg-light {
    background-color: #f7f7f7
}

.lis-latter-spacing-2 {
    letter-spacing: 2px
}

.lis-font-weight-500 {
    font-weight: 500
}

.price-table sup {
    top: -1.5em
}

.price-table sup,
.price-table small {
    font-size: 1.25rem
}

.price-table small {
    font-size: 1.25rem
}

sup {
    position: relative;
    font-size: 75%;
    line-height: 0
}

.lis-brd-light {
    border-color: #dadada !important
}

.lis-line-height-3 {
    line-height: 3 !important
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.lis-line-height-3 {
    line-height: 3 !important
}

.lis-rounded-circle-50 {
    border-radius: 50px
}

.lis-bg-primary {
    background-color: #673ab7
}

.price-table.active {
    transform: scale(1.045);
    -webkit-transform: scale(1.045)
}

.price-table {
    -webkit-transition: 0.3s ease
}

.lis-rounded-bottom {
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px
}

/*Metismenu*/

.sidebar-wrapper ul {
    padding: 0;
    margin: 0;
    list-style: none;
    background: transparent;
}

.sidebar-wrapper .metismenu {
    background: transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.sidebar-wrapper .metismenu li+li {
    margin-top: 5px;
}

.sidebar-wrapper .metismenu li:first-child {
    margin-top: 5px;
}

.sidebar-wrapper .metismenu li:last-child {
    margin-bottom: 5px;
}

.sidebar-wrapper .metismenu>li {
    /*-webkit-box-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
}

.sidebar-wrapper .metismenu a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 8px 15px;
    font-size: 15px;
    color: #5f5f5f;
    outline-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: .5px;
    border: 1px solid #ffffff00;
    transition: all .3s ease-out;
}

.sidebar-wrapper .metismenu a .parent-icon {
    font-size: 24px;
    line-height: 1;
}

.sidebar-wrapper .metismenu a .menu-title {
    margin-left: 10px;
}

.sidebar-wrapper .metismenu ul a {
    padding: 6px 15px 6px 15px;
    font-size: 15px;
    border: 0;
}

.sidebar-wrapper .metismenu ul a i {
    margin-right: 10px;
}

.sidebar-wrapper .metismenu ul {
    border: 1px solid #ededed;
    background: #ffffff;
}

.sidebar-wrapper .metismenu ul ul a {
    padding: 8px 15px 8px 30px;
}

.sidebar-wrapper .metismenu ul ul ul a {
    padding: 8px 15px 8px 45px;
}

.sidebar-wrapper .metismenu a:hover,
.sidebar-wrapper .metismenu a:focus,
.sidebar-wrapper .metismenu a:active,
.sidebar-wrapper .metismenu .mm-active>a {
    color: #673ab7;
    text-decoration: none;
    background: rgb(103 58 183 / 10%);
}

.menu-label {
    padding: 20px 15px 5px 5px;
    color: #b0afaf;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
}

.metismenu .has-arrow:after {
    position: absolute;
    content: "";
    width: .50em;
    height: .50em;
    border-style: solid;
    border-width: 1.2px 0 0 1.2px;
    border-color: initial;
    right: 15px;
    transform: rotate(-45deg) translateY(-50%);
    transform-origin: top;
    top: 50%;
    transition: all .3s ease-out;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    border-top: 1px solid #cccbcb;
    padding: 8px 15px;
    font-size: 13px;
    text-align: center;
    margin-left: 260px;
    transition: all .3s ease-out;
    z-index: 10;
}

.p-tab-name {
    display: none !important;
}

@media screen and (min-width: 576px) {
    .p-tab-name {
        display: block !important;
    }
}

@media screen and (min-width: 1025px) {
    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .sidebar-header .logo-text {
        display: none;
    }
    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .sidebar-header .logo-icon-2 {
        margin-left: 0px;
    }
    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .sidebar-header .toggle-btn {
        display: none;
    }
    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .sidebar-header {
        justify-content: center;
        padding: 10px;
    }
    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper {
        width: 80px;
    }
    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .sidebar-header {
        width: 80px;
    }
    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu a {
        justify-content: center;
    }
    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu .menu-title {
        display: none;
    }
    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu li ul {
        display: none;
    }
    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu li.menu-label {
        display: none;
    }
    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu .has-arrow:after {
        display: none;
    }
    /* .sidebar-header {
        display: none !important;
    } */
    .chat-toggle-btn {
        display: none !important;
    }
    .email-toggle-btn {
        display: none !important;
    }
    .left-topbar {
        display: none !important;
    }
}

/*Forms */

input::placeholder {
    color: #000 !important;
    opacity: .3 !important
}

.custom-file-label {
    font-size: 1rem;
}

.form-check-label {
    font-size: 1rem;
}

.form-text {
    font-size: 13px;
}

.form-check-input:checked {
    background-color: #673ab7;
    border-color: #673ab7;
}

.form-check-input[type=checkbox]:indeterminate {
    background-color: rgba(104, 58, 183, 0.5);
    border-color: rgba(104, 58, 183, 0.5);
}

.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(104, 58, 183, 0.25);
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(104, 58, 183, 0.25);
}

.form-range::-moz-range-thumb {
    background-color: #673ab7;
}

.invalid-feedback {
    font-size: 100%;
    margin-top: 0.55rem;
}

.custom-control-label {
    font-size: 1rem;
}

.select2-container--bootstrap4 .select2-selection--single {
    font-size: 1rem;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__rendered {
    font-size: 1rem;
}

.select2-sm .select2-container--bootstrap4 .select2-selection {
    height: calc(1.5em + .5rem + 2px);
    font-size: .875rem;
}

.select2-container--bootstrap4 .select2-dropdown {
    border-color: rgba(206, 212, 218, 0.55);
    border-radius: 0;
    box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, .10);
}

.g-r-left .select2-container--bootstrap4 .select2-selection {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.g-r-right .select2-container--bootstrap4 .select2-selection {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.scrollspy-1 {
    height: 200px;
    position: relative;
    overflow-y: scroll;
}

.scrollspy-2 {
    height: 200px;
    position: relative;
    overflow-y: scroll;
}

.chip {
    display: inline-block;
    height: 32px;
    padding: 0 12px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.70);
    cursor: pointer;
    background-color: #f1f1f1;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
    box-shadow: none;
}

.chip img {
    float: left;
    width: 32px;
    height: 32px;
    margin: 0 8px 0 -12px;
    border-radius: 50%;
}

.chip .closebtn {
    padding-left: 10px;
    font-weight: bold;
    float: right;
    font-size: 16px;
    cursor: pointer;
}

.chip.chip-md {
    height: 42px;
    line-height: 42px;
    border-radius: 21px;
}

.chip.chip-md img {
    height: 42px;
    width: 42px;
}

.chip.chip-lg {
    height: 52px;
    line-height: 52px;
    border-radius: 26px;
}

.chip.chip-lg img {
    height: 52px;
    width: 52px;
}

/*Components */

.card {
    box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, .10);
    border: 1px solid rgba(0, 0, 0, 0);
    margin-bottom: 1.5rem;
}

.card-header {
    background-color: transparent;
    font-size: 16px;
    text-transform: capitalize;
}

.card-group {
    margin-bottom: 30px;
}

.card-group {
    box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, .10);
}

.card-group .card {
    box-shadow: none;
}

.metismenu-card .card-header {
    cursor: pointer;
}

.card-deck .card {
    margin-bottom: 30px;
}

/*Buttons*/

.btn {
    text-transform: capitalize;
}

.btn-primary {
    color: #fff;
    background-color: #673ab7;
    border-color: #673ab7;
}

.btn-primary {
    color: #fff;
    background-color: #673ab7;
    border-color: #673ab7
}

.btn-primary:hover {
    color: #fff;
    background-color: #5930a1;
    border-color: #5930a1
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
    color: #fff;
    background-color: #5930a1;
    border-color: #5930a1;
    box-shadow: 0 0 0 .25rem rgba(89, 48, 161, 0.5)
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #5930a1;
    border-color: #5930a1
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(89, 48, 161, 0.5)
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #673ab7;
    border-color: #673ab7
}

.btn-success {
    color: #fff;
    background-color: #32ab13;
    border-color: #32ab13
}

.btn-success:hover {
    color: #fff;
    background-color: #2c9b10;
    border-color: #2c9b10
}

.btn-check:focus+.btn-success,
.btn-success:focus {
    color: #fff;
    background-color: #2c9b10;
    border-color: #2c9b10;
    box-shadow: 0 0 0 .25rem rgba(44, 155, 16, 0.5)
}

.btn-check:active+.btn-success,
.btn-check:checked+.btn-success,
.btn-success.active,
.btn-success:active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #2c9b10;
    border-color: #2c9b10
}

.btn-check:active+.btn-success:focus,
.btn-check:checked+.btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(44, 155, 16, 0.5)
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #32ab13;
    border-color: #32ab13
}

.btn-danger {
    color: #fff;
    background-color: #f02769;
    border-color: #f02769
}

.btn-danger:hover {
    color: #fff;
    background-color: #e42061;
    border-color: #e42061
}

.btn-check:focus+.btn-danger,
.btn-danger:focus {
    color: #fff;
    background-color: #e42061;
    border-color: #e42061;
    box-shadow: 0 0 0 .25rem rgba(228, 32, 97, 0.5)
}

.btn-check:active+.btn-danger,
.btn-check:checked+.btn-danger,
.btn-danger.active,
.btn-danger:active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #e42061;
    border-color: #e42061
}

.btn-check:active+.btn-danger:focus,
.btn-check:checked+.btn-danger:focus,
.btn-danger.active:focus,
.btn-danger:active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(228, 32, 97, 0.5)
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #e42061;
    border-color: #e42061
}

.btn-warning {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-warning:hover {
    color: #000;
    background-color: #f5c127;
    border-color: #f5c127
}

.btn-check:focus+.btn-warning,
.btn-warning:focus {
    color: #000;
    background-color: #f5c127;
    border-color: #f5c127;
    box-shadow: 0 0 0 .25rem rgba(245, 194, 39, 0.5)
}

.btn-check:active+.btn-warning,
.btn-check:checked+.btn-warning,
.btn-warning.active,
.btn-warning:active,
.show>.btn-warning.dropdown-toggle {
    color: #000;
    background-color: #f5c127;
    border-color: #f5c127
}

.btn-check:active+.btn-warning:focus,
.btn-check:checked+.btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(245, 194, 39, 0.5)
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #000;
    background-color: #f5c127;
    border-color: #f5c127
}

.btn-info {
    color: #ffffff;
    background-color: #198fed;
    border-color: #198fed
}

.btn-info:hover {
    color: #ffffff;
    background-color: #1684dd;
    border-color: #1684dd
}

.btn-check:focus+.btn-info,
.btn-info:focus {
    color: #ffffff;
    background-color: #1684dd;
    border-color: #1684dd;
    box-shadow: 0 0 0 .25rem rgba(22, 132, 221, 0.5)
}

.btn-check:active+.btn-info,
.btn-check:checked+.btn-info,
.btn-info.active,
.btn-info:active,
.show>.btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #3dd5f3;
    border-color: #25cff2
}

.btn-check:active+.btn-info:focus,
.btn-check:checked+.btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(22, 132, 221, 0.5)
}

.btn-info.disabled,
.btn-info:disabled {
    color: #ffffff;
    background-color: #198fed;
    border-color: #198fed
}

.btn-outline-primary {
    color: #673ab7;
    border-color: #673ab7
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #673ab7;
    border-color: #673ab7
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 .25rem rgba(89, 48, 161, 0.5)
}

.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
    color: #fff;
    background-color: #673ab7;
    border-color: #673ab7
}

.btn-check:active+.btn-outline-primary:focus,
.btn-check:checked+.btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
    box-shadow: 0 0 0 .25rem rgba(89, 48, 161, 0.5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #673ab7;
    background-color: transparent
}

.btn-outline-success {
    color: #32ab13;
    border-color: #32ab13
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #32ab13;
    border-color: #32ab13
}

.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus {
    box-shadow: 0 0 0 .25rem rgba(44, 155, 16, 0.5)
}

.btn-check:active+.btn-outline-success,
.btn-check:checked+.btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
    color: #fff;
    background-color: #32ab13;
    border-color: #32ab13
}

.btn-check:active+.btn-outline-success:focus,
.btn-check:checked+.btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
    box-shadow: 0 0 0 .25rem rgba(44, 155, 16, 0.5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #32ab13;
    background-color: transparent
}

.btn-outline-danger {
    color: #f02769;
    border-color: #f02769
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #f02769;
    border-color: #f02769
}

.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 .25rem rgba(228, 32, 97, 0.5)
}

.btn-check:active+.btn-outline-danger,
.btn-check:checked+.btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
    color: #fff;
    background-color: #f02769;
    border-color: #f02769
}

.btn-check:active+.btn-outline-danger:focus,
.btn-check:checked+.btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
    box-shadow: 0 0 0 .25rem rgba(228, 32, 97, 0.5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #f02769;
    background-color: transparent
}

.btn-outline-info {
    color: #198fed;
    border-color: #198fed
}

.btn-outline-info:hover {
    color: #000;
    background-color: #198fed;
    border-color: #198fed
}

.btn-check:focus+.btn-outline-info,
.btn-outline-info:focus {
    box-shadow: 0 0 0 .25rem rgba(22, 132, 221, 0.5)
}

.btn-check:active+.btn-outline-info,
.btn-check:checked+.btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
    color: #000;
    background-color: #198fed;
    border-color: #198fed
}

.btn-check:active+.btn-outline-info:focus,
.btn-check:checked+.btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
    box-shadow: 0 0 0 .25rem rgba(22, 132, 221, 0.5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #198fed;
    background-color: transparent
}

.accordion-button:not(.collapsed) {
    color: #673ab7;
    background-color: rgb(103 58 183 / 12%);
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button:focus {
    border-color: #673ab7;
    box-shadow: 0 0 0 0.25rem rgb(103 58 183 / 25%);
}

.list-group-item.active {
    background-color: #673ab7;
    border-color: #673ab7;
}

.nav-link {
    color: #673ab7;
}

.page-link {
    color: #673ab7;
}

.page-link:focus {
    z-index: 3;
    color: #673ab7;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(104, 58, 183, 0.5)
}

.page-item.active .page-link {
    background-color: #673ab7;
    border-color: #673ab7;
}

.progress-bar {
    background-color: #673ab7;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #673ab7;
}

.btn i {
    vertical-align: middle;
    font-size: 1.3rem;
    margin-top: -1em;
    margin-bottom: -1em;
}

.btn-white {
    color: #212529;
    background-color: #ffffff;
    border-color: #ced4da;
}

.btn-light-primary {
    color: #673ab7;
    background-color: rgb(103 58 183 / 30%);
    border-color: rgb(103 58 183 / 30%);
}

.btn-light-success {
    color: #32ab13;
    background-color: rgb(50 171 19 / 30%);
    border-color: rgb(50 171 19 / 30%);
}

.btn-light-danger {
    color: #f02769;
    background-color: rgb(240 39 105 / 30%);
    border-color: rgb(240 39 105 / 30%);
}

.btn-light-info {
    color: #198fed;
    background-color: rgb(25 143 237 / 30%);
    border-color: rgb(25 143 237 / 30%);
}

.btn-light-warning {
    color: #212529;
    background-color: rgb(255 193 7 / 30%);
    border-color: rgb(255 193 7 / 30%);
}

.btn-light-secondary {
    color: #5a7684;
    background-color: rgb(90 118 132 / 30%);
    border-color: rgb(90 118 132 / 30%);
}

.btn-light-dark {
    color: #343a40;
    background-color: rgb(52 58 64 / 30%);
    border-color: rgb(52 58 64 / 30%);
}

.btn-facebook {
    box-shadow: 0 2px 2px 0 rgba(59, 89, 152, 0.14), 0 3px 1px -2px rgba(59, 89, 152, 0.2), 0 1px 5px 0 rgba(59, 89, 152, 0.12);
    background-color: #3b5998;
    border-color: #3b5998;
    color: #fff;
}

.btn-facebook:hover {
    color: #fff;
}

.btn-facebook:focus {
    box-shadow: 0 0 0 .2rem rgba(59, 89, 152, 0.5)
}

.btn-twitter {
    box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14), 0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);
    background-color: #55acee;
    border-color: #55acee;
    color: #fff;
}

.btn-twitter:hover {
    color: #fff;
}

.btn-twitter:focus {
    box-shadow: 0 0 0 .2rem rgba(85, 172, 238, 0.5)
}

.btn-linkedin {
    box-shadow: 0 2px 2px 0 rgba(9, 118, 180, 0.14), 0 3px 1px -2px rgba(9, 118, 180, 0.2), 0 1px 5px 0 rgba(9, 118, 180, 0.12);
    background-color: #0976b4;
    border-color: #0976b4;
    color: #fff;
}

.btn-linkedin:hover {
    color: #fff;
}

.btn-linkedin:focus {
    box-shadow: 0 0 0 .2rem rgba(9, 118, 180, 0.5)
}

.btn-youtube {
    box-shadow: 0 2px 2px 0 rgba(229, 45, 39, 0.14), 0 3px 1px -2px rgba(229, 45, 39, 0.2), 0 1px 5px 0 rgba(229, 45, 39, 0.12);
    background-color: #e52d27;
    border-color: #e52d27;
    color: #fff;
}

.btn-youtube:hover {
    color: #fff;
}

.btn-youtube:focus {
    box-shadow: 0 0 0 .2rem rgba(229, 45, 39, 0.5)
}

.btn-github {
    box-shadow: 0 2px 2px 0 rgba(51, 51, 51, 0.14), 0 3px 1px -2px rgba(51, 51, 51, 0.2), 0 1px 5px 0 rgba(51, 51, 51, 0.12);
    background-color: #333333;
    border-color: #333333;
    color: #fff;
}

.btn-github:hover {
    color: #fff;
}

.btn-github:focus {
    box-shadow: 0 0 0 .2rem rgba(51, 51, 51, 0.5)
}

.btn-skype {
    box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14), 0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);
    background-color: #00aff0;
    border-color: #00aff0;
    color: #fff;
}

.btn-skype:hover {
    color: #fff;
}

.btn-skype:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 175, 240, 0.5)
}

.btn-pinterest {
    box-shadow: 0 2px 2px 0 rgba(204, 33, 39, 0.14), 0 3px 1px -2px rgba(204, 33, 39, 0.2), 0 1px 5px 0 rgba(204, 33, 39, 0.12);
    background-color: #cc2127;
    border-color: #cc2127;
    color: #fff;
}

.btn-pinterest:hover {
    color: #fff;
}

.btn-pinterest:focus {
    box-shadow: 0 0 0 .2rem rgba(204, 33, 39, 0.5)
}

.btn-dribbble {
    box-shadow: 0 2px 2px 0 rgba(234, 76, 137, 0.14), 0 3px 1px -2px rgba(234, 76, 137, 0.2), 0 1px 5px 0 rgba(234, 76, 137, 0.12);
    background-color: #ea4c89;
    border-color: #ea4c89;
    color: #fff;
}

.btn-dribbble:hover {
    color: #fff;
}

.btn-dribbble:focus {
    box-shadow: 0 0 0 .2rem rgba(234, 76, 137, 0.5)
}

.btn-group-round button:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.btn-group-round button:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

/*Background Colors*/

.bg-primary {
    background-color: #673ab7 !important;
}

.bg-secondary {
    background-color: #5a7684 !important;
}

.bg-success {
    background-color: #32ab13 !important;
}

.bg-danger {
    background-color: #f02769 !important;
}

.bg-warning {
    background-color: #ffc107 !important;
}

.bg-info {
    background-color: #198fed !important;
}

.bg-dark {
    background-color: #343a40 !important;
}

.bg-light {
    background-color: #f8f9fa !important;
}

.bg-login-color {
    background-color: #5f4a99 !important;
}

.bg-rose {
    background-color: #ff007c !important;
}

.bg-voilet {
    background-color: #7d00b5 !important;
}

.bg-dribbble {
    background-color: #ea4c89 !important;
}

.bg-facebook {
    background-color: #3b5998 !important;
}

.bg-twitter {
    background-color: #55acee !important;
}

.bg-google {
    background-color: #e52d27 !important;
}

.bg-linkedin {
    background-color: #0976b4 !important;
}

.bg-youtube {
    background-color: #f8130b !important;
}

.bg-tumblr {
    background-color: #34526f !important;
}

.bg-vimeo {
    background-color: #86c9ef !important;
}

.bg-body {
    background-color: #f6f6f6 !important;
}

.bg-gray {
    background-color: #dee1e6 !important;
}

.bg-red-light {
    background-color: #fe6555 !important;
}

.bg-primary-blue {
    background-color: #265ed7 !important;
}

.bg-dark-blue {
    background-color: #0c1b7a !important;
}

.bg-shine-info {
    background-color: #54dee4 !important;
}

.bg-wall {
    background-color: #00cd98 !important;
}

.bg-sunset {
    background-color: #ff8b01 !important;
}

.bg-light-primary {
    background-color: rgb(103 58 183 / 15%) !important;
}

.bg-light-success {
    background-color: rgb(50 171 19 / 15%) !important
}

.bg-light-danger {
    background-color: rgb(250 146 180 / 15%) !important;
}

.bg-light-warning {
    background-color: rgb(255 193 7 / 15%) !important
}

.bg-light-info {
    background-color: rgb(205, 233, 255) !important;
}

.bg-light-purple {
    background-color: #dcdcff !important;
}

.bg-light-shineblue {
    background-color: rgb(1 215 255 / 15%) !important
}

.bg-light-cyne {
    background-color: rgb(0 139 139 / 15%) !important
}

.bg-split-primary {
    background-color: #4f2894;
    border-color: #4f2894;
}

.bg-light-sinata {
    background-color: rgb(165 43 187 / 15%) !important
}

.bg-light-mehandi {
    background: rgb(195 240 36 / 26%) !important
}

.bg-gradient-danger {
    background-image: linear-gradient(to left, #ff758c 0%, #ff7eb3 100%);
}

.bg-gradient-voilet {
    background-image: linear-gradient(to left, #a18cd1 0%, #fbc2eb 100%);
}

.bg-gradient-success {
    background-image: linear-gradient(to left, #05ac9d 0%, #20cfbf 100%);
}

.bg-gradient-mehandi {
    background-image: linear-gradient(to right, #dbe287, #cac531);
}

/*Text Colors*/

.text-primary {
    color: #673ab7 !important;
}

.text-danger {
    color: #f02769 !important;
}

.text-success {
    color: #32ab13 !important;
}

.text-facebook {
    color: #3b5998 !important;
}

.text-twitter {
    color: #55acee !important;
}

.text-youtube {
    color: #f8130b !important;
}

.text-linkedin {
    color: #0a66c2 !important;
}

.text-skype {
    color: #00aff0 !important;
}

.text-purple {
    color: #8280ff !important;
}

.text-shineblue {
    color: #01d7ff !important;
}

.text-cyne {
    color: #008b8b !important;
}

.text-primary-blue {
    color: #265ed7 !important;
}

.text-dark-blue {
    color: #0c1b7a !important;
}

.text-shine-info {
    color: #54dee4 !important;
}

.text-grey {
    color: #dee1e6 !important;
}

.text-red {
    color: #ff392b !important;
}

.text-sinata {
    color: #a52bbb !important;
}

.text-mehandi {
    color: #839838 !important;
}

.border-lg-top-primary {
    border-top: 4px solid #673ab7;
}

.border-lg-top-info {
    border-top: 4px solid #198fed;
}

.border-lg-top-danger {
    border-top: 4px solid #f02769;
}

.icon-color-1 {
    color: #ff3030;
}

.icon-color-2 {
    color: #ff007c;
}

.icon-color-3 {
    color: #4CAF50;
}

.icon-color-4 {
    color: #ff8b01;
}

.icon-color-5 {
    color: #a400ed;
}

.icon-color-6 {
    color: #009688;
}

.icon-color-7 {
    color: #03A9F4;
}

.icon-color-8 {
    color: #c37458;
}

.icon-color-9 {
    color: #265ed7;
}

.icon-color-10 {
    color: #30d4e4;
}

.icon-color-11 {
    color: #ca9805;
}

.icon-color-12 {
    color: #a24f30;
}

/*Authentication*/

.section-authentication-login {
    height: 100vh;
    padding-top: 1.0rem;
    padding-bottom: 1.0rem
}

.section-authentication-register {
    height: 100%;
    padding: 1.5rem;
}

.authentication-forgot {
    height: 100vh;
    padding: 0 1rem;
}

.forgot-box {
    width: 27rem;
    border-radius: 30px;
}

.authentication-reset-password {
    height: 100vh;
    padding: 0 1rem;
}

.authentication-lock-screen {
    height: 100vh;
    padding: 0 1rem;
}

.error-404 {
    height: 100vh;
    padding: 0 1rem;
}

.login-card {
    border-right: 1px solid #dee2e6 !important;
}

.error-social a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    margin: 5px;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.profile-social a {
    display: inline-block;
    width: 33px;
    height: 33px;
    line-height: 33px;
    font-size: 15px;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    margin: 2px;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.bg-forgot {
    /* background-image: url(../images/login-images/bg-forgot-password.jpg); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.bg-lock-screen {
    /* background-image: url(../images/login-images/bg-lock-screen.jpg); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.bg-login {
    /* background-image: url(../images/login-images/bg-login-img.jpg); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.bg-register {
    /* background-image: url(../images/login-images/bg-register-img.jpg); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.bg-coming-soon {
    /* background-image: url(../images/login-images/bg-register-img.jpg); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

/*File Manager*/

.fm-wrapper {
    position: relative;
}

.fm-left-sidebar {
    background: #ffffff;
    width: 220px;
    height: 100%;
    position: fixed;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.fm-body {
    background: white;
    width: 100%;
    height: 100%;
}

.fm-menu .list-group a {
    font-size: 16px;
    color: #5f5f5f;
    display: flex;
    align-items: center;
}

.fm-menu .list-group a i {
    font-size: 23px;
}

.fm-menu .list-group a:hover {
    background: #673ab7;
    color: #ffffff;
    transition: all .2s ease-out;
}

.fm-icon-box {
    font-size: 32px;
    background: #ffffff;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .25rem;
}

.fm-file-box {
    font-size: 25px;
    background: #e9ecef;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .25rem;
}

.user-groups img {
    margin-left: -14px;
    border: 1px solid #e4e4e4;
    padding: 2px;
    cursor: pointer;
}

.user-plus {
    width: 33px;
    height: 33px;
    margin-left: -14px;
    line-height: 33px;
    background: #ffffff;
    border-radius: 50%;
    text-align: center;
    font-size: 22px;
    cursor: pointer;
    border: 1px dotted #a9b2bb;
    color: #404142;
}

/*Tables*/

.table-responsive {
    white-space: nowrap;
}

.table td,
.table th {
    vertical-align: middle;
    color: #6d757b;
}

/*Invoice Page*/

#invoice {
    padding: 0px;
}

.invoice {
    position: relative;
    background-color: #FFF;
    min-height: 680px;
    padding: 15px
}

.invoice header {
    padding: 10px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #673ab7
}

.invoice .company-details {
    text-align: right
}

.invoice .company-details .name {
    margin-top: 0;
    margin-bottom: 0
}

.invoice .contacts {
    margin-bottom: 20px
}

.invoice .invoice-to {
    text-align: left
}

.invoice .invoice-to .to {
    margin-top: 0;
    margin-bottom: 0
}

.invoice .invoice-details {
    text-align: right
}

.invoice .invoice-details .invoice-id {
    margin-top: 0;
    color: #673ab7
}

.invoice main {
    padding-bottom: 50px
}

.invoice main .thanks {
    margin-top: -100px;
    font-size: 2em;
    margin-bottom: 50px
}

.invoice main .notices {
    padding-left: 6px;
    border-left: 6px solid #673ab7;
    background: #e7f2ff;
    padding: 10px;
}

.invoice main .notices .notice {
    font-size: 1.2em
}

.invoice table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 20px
}

.invoice table td,
.invoice table th {
    padding: 15px;
    background: #eee;
    border-bottom: 1px solid #fff
}

.invoice table th {
    white-space: nowrap;
    font-weight: 400;
    font-size: 16px
}

.invoice table td h3 {
    margin: 0;
    font-weight: 400;
    color: #673ab7;
    font-size: 1.2em
}

.invoice table .qty,
.invoice table .total,
.invoice table .unit {
    text-align: right;
    font-size: 1.2em
}

.invoice table .no {
    color: #fff;
    font-size: 1.6em;
    background: #673ab7
}

.invoice table .unit {
    background: #ddd
}

.invoice table .total {
    background: #673ab7;
    color: #fff
}

.invoice table tbody tr:last-child td {
    border: none
}

.invoice table tfoot td {
    background: 0 0;
    border-bottom: none;
    white-space: nowrap;
    text-align: right;
    padding: 10px 20px;
    font-size: 1.2em;
    border-top: 1px solid #aaa
}

.invoice table tfoot tr:first-child td {
    border-top: none
}

.invoice table tfoot tr:last-child td {
    color: #673ab7;
    font-size: 1.4em;
    border-top: 1px solid #673ab7
}

.invoice table tfoot tr td:first-child {
    border: none
}

.invoice footer {
    width: 100%;
    text-align: center;
    color: #777;
    border-top: 1px solid #aaa;
    padding: 8px 0
}

@media print {
    .invoice {
        font-size: 11px !important;
        overflow: hidden !important
    }
    .invoice footer {
        position: absolute;
        bottom: 10px;
        page-break-after: always
    }
    .invoice>div:last-child {
        page-break-before: always
    }
}

.main-row {
    height: 100vh;
}

.main-col {
    max-width: 500px;
    min-height: 300px;
}

.todo-done {
    text-decoration: line-through;
}

/*Chat box*/

.chat-wrapper {
    width: auto;
    height: 600px;
    border-radius: 0.25rem;
    position: relative;
    background: #ffffff;
    box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, .10);
}

.chat-sidebar {
    width: 340px;
    height: 100%;
    position: absolute;
    background: #ffffff;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    overflow: hidden;
    border-right: 1px solid rgba(0, 0, 0, .125);
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.chat-sidebar-header {
    width: auto;
    height: auto;
    position: relative;
    background: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    border-right: 0px solid rgba(0, 0, 0, .125);
    border-top-left-radius: 0.25rem;
    padding: 15px;
}

.chat-sidebar-content {
    padding: 0px;
}

.chat-user-online {
    position: relative;
}

.chat-sidebar-header .chat-user-online:before {
    content: '';
    position: absolute;
    bottom: 7px;
    left: 40px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #fff;
    background: #16e15e;
}

.chat-list .chat-user-online:before {
    content: '';
    position: absolute;
    bottom: 7px;
    left: 36px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #fff;
    background: #16e15e;
}

.chat-content {
    margin-left: 340px;
    padding: 85px 15px 15px 15px;
}

.chat-header {
    position: absolute;
    height: 70px;
    left: 340px;
    right: 0;
    top: 0;
    padding: 15px;
    background: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    border-top-right-radius: 0.25rem;
    z-index: 1;
}

.chat-footer {
    position: absolute;
    height: 70px;
    left: 340px;
    right: 0;
    bottom: 0;
    padding: 15px;
    background: #f8f9fa;
    border-top: 1px solid rgba(0, 0, 0, .125);
    border-bottom-right-radius: 0.25rem;
}

.chat-footer-menu a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    color: #6c757d;
    text-align: center;
    border-radius: 50%;
    margin: 3px;
    background-color: white;
    border: 1px solid rgb(0 0 0 / 15%);
}

.chat-tab-menu li a.nav-link {
    padding: .3rem 0.2rem;
    line-height: 1.2;
    color: #4a4b4c;
}

.chat-tab-menu .nav-pills .nav-link.active,
.chat-tab-menu .nav-pills .show>.nav-link {
    color: #673ab7;
    background-color: rgb(0 123 255 / 0%);
}

.chat-title {
    font-size: 16px;
    color: #272b2f;
}

.chat-msg {
    font-size: 14px;
    color: #6c757d;
}

.chat-time {
    font-size: 13px;
    color: #6c757d;
}

.chat-list {
    position: relative;
    height: 300px;
}

.chat-list .list-group-item {
    border: 1px solid rgb(0 0 0 / 0%);
    background-color: transparent;
}

.chat-list .list-group-item:hover {
    border: 1px solid rgb(0 0 0 / 0%);
    background-color: rgb(103 58 183 / 15%);
}

.chat-list .list-group-item.active {
    background-color: rgb(103 58 183 / 15%);
}

.chart-online {
    color: #16e15e;
}

.chat-top-header-menu a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    color: #6c757d;
    text-align: center;
    border-radius: 50%;
    margin: 3px;
    background-color: white;
    border: 1px solid rgb(0 0 0 / 15%);
}

.chat-content {
    position: relative;
    width: auto;
    height: 520px;
}

.chat-content-leftside .chat-left-msg {
    width: fit-content;
    background-color: #eff2f5;
    padding: 0.80rem;
    border-radius: 12px;
    max-width: 480px;
    text-align: left;
    border-top-left-radius: 0;
}

.chat-content-rightside .chat-right-msg {
    width: fit-content;
    background-color: #dcedff;
    padding: 0.80rem;
    border-radius: 12px;
    float: right;
    max-width: 480px;
    text-align: left;
    border-bottom-right-radius: 0;
}

.chat-toggle-btn {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-right: 15px;
    text-align: center;
    font-size: 24px;
    color: #6c757d;
    border-radius: 50%;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgb(0 0 0 / 15%);
}

/*Email box*/

.email-wrapper {
    width: auto;
    height: 600px;
    overflow: hidden;
    border-radius: 0.25rem;
    position: relative;
    background: #ffffff;
    box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, .10);
}

.email-sidebar {
    width: 250px;
    height: 100%;
    position: absolute;
    background: #fff;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    overflow: hidden;
    border-right: 1px solid rgba(0, 0, 0, .125);
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.email-sidebar-header {
    width: auto;
    height: auto;
    position: relative;
    background: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    border-right: 0px solid rgba(0, 0, 0, .125);
    border-top-left-radius: 0.25rem;
    padding: 15px;
}

.email-navigation {
    position: relative;
    padding: 0px;
    height: 345px;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.email-header {
    position: absolute;
    height: 70px;
    left: 250px;
    right: 0;
    top: 0;
    padding: 15px;
    background: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    border-top-right-radius: 0.25rem;
    z-index: 1;
}

.email-content {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    top: 70px;
    height: auto;
    margin-left: 250px;
    padding: 0;
    background: #ffffff;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.email-navigation a.list-group-item {
    color: #404142;
    padding: .35rem 1.25rem;
    background-color: white;
    border-bottom: 1px solid rgb(0 0 0 / 0%);
    transition: all .3s ease-out;
}

.email-navigation a.list-group-item:hover {
    background-color: rgb(103 58 183 / 12%);
}

.email-navigation a.list-group-item.active {
    color: #673ab7;
    font-weight: 600;
    background-color: rgb(103 58 183 / 12%);
}

.email-meeting {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.email-meeting a.list-group-item {
    color: #404142;
    padding: .35rem 1.25rem;
    background-color: white;
    border-bottom: 1px solid rgb(0 0 0 / 0%);
}

.email-meeting a.list-group-item:hover {
    background-color: rgb(0 123 255 / 15%);
    transition: all .3s ease-out;
}

.email-hangout .chat-user-online:before {
    content: '';
    position: absolute;
    bottom: 7px;
    left: 37px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #fff;
    background: #16e15e;
}

.email-toggle-btn {
    width: auto;
    height: auto;
    margin-right: 10px;
    text-align: center;
    font-size: 24px;
    color: #404142;
    border-radius: 0;
    cursor: pointer;
    background-color: white;
    border: 0px solid rgb(0 0 0 / 15%);
}

.email-actions {
    width: 230px;
    ;
}

.email-time {
    font-size: 13px;
    color: #6c757d;
}

.email-list div.email-message {
    background: #ffffff;
    border-bottom: 1px solid rgb(0 0 0 / 8%);
    color: #383a3c;
}

.email-list div.email-message:hover {
    transition: all .2s ease-out;
    background-color: #eceef1;
}

.email-list {
    position: relative;
    height: 530px;
}

.email-star {
    color: #6c757d;
}

.email-read-box {
    position: relative;
    height: 530px;
}

/*Compose Mail*/

.compose-mail-popup {
    width: 42%;
    position: fixed;
    bottom: -30px;
    right: 30px;
    z-index: 15;
    display: none;
}

.compose-mail-toggled {
    display: block;
}

.compose-mail-title {
    font-size: 16px;
}

.compose-mail-close {
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    font-size: 14px;
    border-radius: 2px;
    background-color: rgb(255 255 255 / 0%);
}

.compose-mail-close:hover {
    background-color: rgb(255 255 255 / 20%);
}

/* Navbars */

.nav-search input.form-control {
    background-color: rgb(255 255 255 / 20%);
    border: 1px solid rgb(255 255 255 / 45%);
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.nav-search input.form-control::placeholder {
    opacity: 0.5 !important;
    color: #fff !important;
}

.nav-search input.form-control::-ms-input-placeholder {
    color: #fff !important;
}

.nav-search button[type='submit'] {
    background-color: rgb(255 255 255 / 20%);
    border: 1px solid rgb(255 255 255 / 32%);
    color: #fff;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

.nav-width {
    width: 340px !important;
}

.round-pagination.pagination .page-item:first-child .page-link {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.round-pagination.pagination .page-item:last-child .page-link {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

/*Extra Css*/

.login-separater span {
    position: relative;
    top: 26px;
    margin-top: -10px;
    background: #ffffff;
    padding: 5px;
    font-size: 12px;
    color: #cbcbcb;
    z-index: 1;
}

.login-img {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.contacts-social a {
    font-size: 16px;
    width: 26px;
    height: 26px;
    line-height: 26px;
    background: #f7f5f5;
    text-align: center;
    border-radius: 0.25rem;
}

.iconFilterTypes {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.iconFilterTypes .tab.active,
.iconFilterTypes .tab:hover {
    font-weight: 700;
    color: #756af8;
}

.iconFilterTypes .tab {
    color: #000;
    font-weight: 700;
    display: inline-block;
    cursor: pointer;
}

.list {
    position: relative;
}

ul.icons {
    list-style: none;
}

ul.icons li {
    position: relative;
}

ul.icons li {
    cursor: pointer;
    padding: 10px;
    width: 204px;
    float: left;
    box-shadow: 0 1px 5px #e3e7eb;
    margin: 13px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: #f8f9fb;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-transition: all .1s linear;
    -moz-transition: all .1s linear;
    -o-transition: all .1s linear;
    transition: all .1s linear;
}

ul.icons li i {
    color: #673ab7;
    width: 30px;
    height: 30px;
    font-size: 30px;
    text-align: center;
    display: block;
    float: left;
    line-height: 34px;
    margin-left: 5px;
}

ul.icons li:hover {
    background: #eef1f7;
}

ul.icons li span {
    text-indent: 15px;
    display: block;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pByfF {
    animation-fill-mode: forwards;
    cursor: pointer;
    display: inline-block;
    flex: 0 0 10%;
    transition: all 0.15s ease 0s;
    border-radius: 8px;
    overflow: hidden;
    outline: none !important;
    background: #f8f9fb;
    box-shadow: 0 1px 5px #e3e7eb;
    width: 122px;
    height: 122px;
    margin: 7px 7px;
    padding: 10px 14px;
}

.pByfF.active .icon-box-inner {
    background: rgb(238, 238, 238);
}

.pByfF .icon-box-inner {
    font-weight: 700;
    position: relative;
    font-size: 16px;
    line-height: 35px;
    text-align: center;
    pointer-events: none;
    margin: 1px;
    border-radius: 8px;
    background: transparent;
    padding: 18px 10px;
}

.pByfF i {
    color: #673ab7;
    font-size: 36px;
}

.pByfF .icon-box-inner .icon-box-name {
    font-size: 12px;
    text-transform: capitalize;
    user-select: none;
    color: #a5a5a5;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 15px;
    overflow: hidden;
    margin: 5px auto 2px;
}

.pByfF:hover {
    background: #eaecf1;
}

.media-icons {
    font-size: 54px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
}

.dashboard-icons {
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
    font-size: 42px;
    border-radius: 10px;
}

.widgets-icons {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
    font-size: 26px;
    border-radius: 10px;
}

.widgets-social {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
    font-size: 22px;
    border-radius: 10px;
}

.icon-box {
    padding: 10px 14px;
    background: #ffffff;
    box-shadow: 0 1px 5px #e3e7eb;
}

.icon-symbol i {
    font-size: 30px;
    color: #673ab7;
}

.icon-name {}

.p-10 {
    padding: 10px;
}

.p-15 {
    padding: 15px;
}

.font-small {
    font-size: 50%;
}

.font-13 {
    font-size: 13px;
}

.font-14 {
    font-size: 14px;
}

.font-20 {
    font-size: 20px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.font-30 {
    font-size: 30px;
}

.font-35 {
    font-size: 35px;
}

.font-40 {
    font-size: 40px;
}

.font-60 {
    font-size: 60px;
}

.radius-10 {
    border-radius: 10px;
}

.radius-15 {
    border-radius: 15px;
}

.radius-30 {
    border-radius: 30px;
}

.row.row-group>div {
    border-right: 1px solid rgb(0 0 0 / 12%);
}

.row.row-group>div:last-child {
    border-right: none;
}

.accordion .card-header:after {
    font-family: 'LineIcons';
    content: "\eb2c";
    float: right;
    color: #673ab7;
}

.accordion .card-header.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\eb53";
}

.color-acordians .accordion .card-header:after {
    color: #fff;
}

.cursor-pointer {
    cursor: pointer;
}

.chart-container1 {
    position: relative;
    height: 340px;
}

.chart-container2 {
    position: relative;
    height: 400px;
}

.chart-container3 {
    position: relative;
    width: 200px;
    height: 80px;
    right: -1.25rem;
    top: -1.0rem;
}

.gmaps,
.gmaps-panaroma {
    height: 400px;
    background: #eeeeee;
    border-radius: 3px;
}

#location-map {
    width: 100%;
    height: 260px;
}

#geographic-map {
    width: 100%;
    height: 340px;
}

.product-img {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f3f3;
    border-radius: 10px;
}

.page-breadcrumb .breadcrumb {
    background-color: transparent;
}

.breadcrumb-title {
    font-size: 20px;
    border-right: 1.5px solid #aaa4a4;
}

.page-breadcrumb .breadcrumb li a {}

.page-breadcrumb .breadcrumb li.breadcrumb-item {
    font-size: 16px;
}

.page-breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: #6c757d;
    font-family: 'LineIcons';
    content: "\ea5c";
}

.vertical-align-middle {
    vertical-align: middle;
}

.back-to-top {
    display: none;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 26px;
    color: white;
    position: fixed;
    border-radius: 10px;
    bottom: 20px;
    right: 12px;
    background-color: #673ab7;
    z-index: 10000;
}

.back-to-top:hover {
    color: white;
    background-color: #000;
    transition: all .5s;
}

.vertical-separater {
    width: 1px;
    background: #dadada;
    height: 50px;
    margin: auto;
}

/*Switcher*/

.btn-switcher {
    position: fixed;
    right: 0px;
    top: 40%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.offcanvas-end {
    width: 300px;
}

/*Responsive*/

@media screen and (max-width: 1280px) {
    .left-topbar {
        width: auto;
    }
    .search-bar {
        padding: 0rem 1.4rem;
    }
    .email-header {
        height: auto;
    }
    .email-content {
        padding: 100px 0px 0px 0px;
    }
}

@media screen and (max-width: 1024px) {
    .top-header {
        left: 0px;
    }
    .search-arrow-back {
        display: inline-block;
        margin-right: 0px;
    }
    .search-btn-mobile {
        display: flex;
    }
    .search-bar {
        display: none;
    }
    .full-search-bar {
        display: flex;
        align-items: center;
        width: 100%;
        position: absolute;
        left: 0;
        z-index: 100;
        background: #fff;
        height: 70px;
        padding: 0rem 1.4rem;
    }
    .sidebar-wrapper {
        width: 260px !important;
        left: -260px;
        box-shadow: none;
        z-index: 12;
    }
    .page-content-wrapper {
        margin-left: 0px;
    }
    .wrapper.toggled .sidebar-wrapper {
        left: 0px;
        box-shadow: 0 .3rem 0.6rem rgba(0, 0, 0, .13);
        transition: all .2s ease-out;
        z-index: 16;
    }
    .wrapper.toggled .page-content-wrapper {
        margin-left: 0px;
    }
    .wrapper.toggled .overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #000;
        opacity: 0.6;
        z-index: 15;
        display: block;
        cursor: move;
        transition: all .2s ease-out;
    }
    .footer {
        margin-left: 0px;
    }
    .wrapper.toggled .footer {
        margin-left: 0px;
    }
    .chat-header {
        border-top-left-radius: 0.25rem;
    }
    .chat-footer {
        border-bottom-left-radius: 0.25rem;
    }
    .chat-sidebar {
        left: -370px;
    }
    .chat-content {
        margin-left: 0px;
    }
    .chat-header {
        left: 0px;
    }
    .chat-footer {
        left: 0px;
    }
    /* chat toggled css */
    .chat-toggled .chat-sidebar {
        left: 0px;
    }
    .chat-toggled .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 340px;
        background: #000;
        opacity: 0.5;
        z-index: 11;
        display: block;
        cursor: move;
        transition: all .3s ease-out;
    }
    .email-header {
        border-top-left-radius: 0.25rem;
    }
    .email-sidebar {
        left: -280px;
    }
    .email-content {
        margin-left: 0px;
    }
    .email-header {
        left: 0px;
    }
    /* email toggled */
    .email-toggled .email-sidebar {
        left: 0px;
    }
    .email-toggled .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 250px;
        background: #000;
        opacity: 0.5;
        z-index: 9;
        display: block;
        cursor: move;
        transition: all .3s ease-out;
    }
}

@media only screen and (max-width: 1199px) {
    .row.row-group>div {
        border-right: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
}

@media screen and (max-width: 991px) {
    .section-authentication-login {
        height: 100%;
        padding: 1.4rem;
    }
    .login-img {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }
    .login-card {
        border-right: 0px solid #dee2e6 !important;
        border-bottom: 1px solid #dee2e6 !important;
    }
    .authentication-reset-password {
        height: auto;
        padding: 2.0rem 1rem;
    }
    .authentication-lock-screen {
        height: auto;
        padding: 2.0rem 1rem;
    }
    .error-404 {
        height: auto;
        padding: 6.0rem 1rem;
    }
    .compose-mail-popup {
        width: auto;
        position: fixed;
        bottom: -30px;
        right: 0;
        left: 0;
    }
}

@media screen and (max-width: 767px) {
    .user-info {
        display: none;
    }
    .lang span {
        display: none;
    }
    .right-topbar .navbar-nav .nav-link {
        padding: 0rem 14px;
        border-left: 0px solid #ededed;
        font-size: 25px;
    }
    .msg-count {
        left: 26px;
    }
    .search-bar .btn-search {
        padding: 0 20px;
    }
    .authentication-forgot {
        height: auto;
        padding: 2.5rem 1rem;
    }
    .fc .fc-toolbar {
        display: flow-root;
    }
}

@media screen and (max-width: 620px) {
    .top-header .navbar .dropdown-menu::after {
        display: none;
    }
    .top-header .navbar .dropdown {
        position: static !important;
    }
    .top-header .navbar .dropdown-menu {
        width: 100% !important;
    }
    .dropdown-lg-content {
        max-height: 350px;
        overflow-y: scroll;
    }
}

@media screen and (max-width: 520px) {
    .logo-icon {
        display: none;
    }
    .chat-footer-menu,
    .chat-top-header-menu {
        display: none;
    }
}