.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Take up full screen height */
}

.loader {
  display: flex;
  justify-content: space-around;
  width: 80px;
}

.dot {
  width: 16px;
  height: 16px;
  background-color: #3498db;
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out both;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}
.dot:nth-child(3) {
  animation-delay: -0.32s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
