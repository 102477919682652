.data-table-level {
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
}

.table-part-level {
  margin-bottom: 20px;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.level-heading {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto; /* Enable horizontal scrolling on smaller screens */
}

.user-table th,
.user-table td {
  border: 1px solid #ced4da;
  padding: 8px;
  text-align: left;
}

.user-table th {
  background-color: #f8f9fa;
  color: #495057;
}

.user-table tbody tr:nth-child(even) {
  background-color: #f8f9fa;
}

.user-table tbody tr:hover {
  background-color: #e9ecef;
}

.pagination-controls {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 4px;
}

.page-btn:hover {
  background-color: #0056b3;
}

.current-page {
  margin: 0 10px;
  font-weight: bold;
  font-size: 1.1rem;
}
.table-part-level {
  overflow: auto;
}
@media screen and (max-width: 768px) {
  .user-table {
    width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling on smaller screens */
  }

  .page-btn {
    font-size: 0.8rem;
    padding: 6px 12px;
  }
}
.current-bal {
  overflow: scroll;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 0 auto;
}

.current-bal-child {
  flex: 1;
  text-align: center;
  margin: 0 10px;
}

.current-bal-child h1 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

.current-bal-child .points {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  color: #555;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}
